import React from "react";
import "./Career.css";
// import Header from '../Header/Header';

const Career = () => {
  //   const templatId = "template_8472y79"

  //   const serviceId = ""

  //   const UserId = ""

  //     const [file, setFile] = useState(null);

  //     const handleFileChange = (e) => {
  //       setFile(e.target.files[0]);
  //     };

  //     const handleSubmit = (e) => {
  //       e.preventDefault();

  //       // You would typically send the file to the server or use an email service like EmailJS
  //       if (file) {
  //         // Handle file upload logic here
  //         console.log('CV Uploaded:', file.name);
  //         alert(`Your CV (${file.name}) has been submitted successfully!`);
  //       } else {
  //         alert('Please upload your CV.');
  //       }
  //     };

  return (
    <div className="career">
      {/* 
        <Header/> */}

      <div className="career_container">
        <h1>Career</h1>

        <h5>We have been waiting for you</h5>
        <p>
          "Your career is a journey of growth, discovery, and endless
          possibilities—embrace the path that leads you to your true potential."
        </p>
        <h6>Please send your resume to</h6>

        <a
          href="mailto:career@spinecodes.in"
          target="_blank"
          rel="noreferrer noopener"
        >
          <h6 className="aTag">career@spinecodes.in</h6>
        </a>
      </div>
    </div>
  );
};

export default Career;
