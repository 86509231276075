import React from "react";
import "./Mobilemenu.css";
import { useNavigate } from "react-router-dom";

function Mobilemenu({ isMenuOpen, setIsMenuOpen }) {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="mobile-menu">
      <ul className={`navbar ${isMenuOpen ? "open" : ""}`}>
        <li
          onClick={() => {
            scrollToSection("spotlight");
            setIsMenuOpen(false);
          }}
        >
          Home
        </li>
        <li
          onClick={() => {
            scrollToSection("service");
            setIsMenuOpen(false);
          }}
        >
          Services
        </li>
        <li
          onClick={() => {
            scrollToSection("work");
            setIsMenuOpen(false);
          }}
        >
          Projects
        </li>
        <li onClick={() => navigate("/career")}>Career</li>
        <li
          onClick={() => {
            scrollToSection("footer");
            setIsMenuOpen(false);
          }}
        >
          Contact Us
        </li>
      </ul>
    </div>
  );
}

export default Mobilemenu;
