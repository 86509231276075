import React, { useEffect, useState } from "react";
import "./ProjectList.css";
import { data } from "../../Data/data"; // Ensure this contains category field
import { useLocation } from "react-router-dom";
import { Shimmer } from "react-shimmer";

function ProjectList() {
  const projectTop = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    // Simulating data loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectTop]);

  const categories = [
    "ALL",
    "CROWD FUNDING",
    "EDUCATION",
    "BUSINESS AUTOMATION",
    "HOTEL MANAGEMENT",
    "E-COMMERCE",
  ];

  // Update the filtered data when category changes
  useEffect(() => {
    if (selectedCategory === "ALL") {
      setFilteredData(data); // Show all projects
    } else {
      setFilteredData(
        data.filter((item) => item.category === selectedCategory)
      );
    }
  }, [selectedCategory]);

  // Filtering logic
  // const filteredData =
  //   selectedCategory === "All"
  //     ? data
  //     : data.filter((item) => item.category === selectedCategory);

  return (
    <div className="project-list">
      <h2>Spotlight Works</h2>

      {/* Category Buttons */}
      <div className="button-container">
        {categories.map((category, index) => (
          <button
            key={index}
            // className={project-bttn ${selectedCategory === category ? "active" : ""}}
            className={`project-bttn ${
              selectedCategory === category ? "active" : ""
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            {/* <div>
              <span>{category}</span>
              <span>{category}</span>
            </div> */}
            {category}
          </button>
        ))}
      </div>

      {/* Project Cards */}
      <div className="project-cards">
        {loading
          ? data.map((item) => (
              <div className="item-card" key={item.name}>
                <div className="image-box">
                  <Shimmer
                    width={130}
                    height={150}
                    innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                    style={{ marginBottom: "10px" }}
                  />
                </div>
                <Shimmer
                  width={350}
                  height={30}
                  innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  style={{ marginBottom: "5px" }}
                />
                <Shimmer
                  width={350}
                  height={30}
                  innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  style={{ marginBottom: "5px" }}
                />
                <Shimmer
                  width={350}
                  height={30}
                  innerStyle={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                />
              </div>
            ))
          : filteredData.map((item) => (
              <div className="item-card" key={item.name}>
                <div className="image-box">
                  <img src={item.image} alt="Campaign" loading="lazy" />
                </div>
                <h6>{item.name}</h6>
                <p>{item.description}</p>
              </div>
            ))}
      </div>
    </div>
  );
}

export default ProjectList;
