import { assets } from "../Assets/Assets";

export const data = [
  {
    name: "SAVE ABDUL RAHIM",
    subname: "SAVE ABDUL RAHIM",
    description:
      "The 'Save Abdul Rahim' app helps Abdul Rahim, who's been in a Saudi jail for 17 years, by letting people and organizations donate to his legal defense fund and support efforts to get him released.",
    image: assets.SaveAbdul,
    category: "CROWD FUNDING",
  },
  {
    name: "Quaid E Millath",
    subname: "IUML",
    description:
      "Its an application for IUML National Committee in their noble cause of building construction in Delhi through a dedicated fund collection program.",
    image: assets.Millath,
    category: "CROWD FUNDING",
  },
  {
    name: "FOR WAYANAD",
    subname: "FOR WAYANAD",
    description:
      "The 'FOR WAYANAD' app helps those affected by the recent Wayanad landslide by enabling secure donations for relief, rebuilding, and assisting those impacted by the disaster.",
    image: assets.Wayanad,
    category: "CROWD FUNDING",
  },
  {
    name: "KPCC 138",
    subname: "KPCC",
    description:
      "KPCC simplifies fundraising by providing a secure platform for individuals to make donations.",
    image: assets.kpcc,
    category: "CROWD FUNDING",
  },
  {
    name: "ROYAL M",
    subname: "ROYAL M",
    description:
      "Application for Royal M, is a catering HR provider in Kerala with a millions-dollar turnover",
    image: assets.Royal,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "KREA EXAM",
    subname: "KREA EXAM",
    description:
      "KREA application is an excellent platform for  candidates who are preparing for civil service  examinations",
    image: assets?.Krea,
    category: "EDUCATION",
  },
  {
    name: "POSITRON",
    subname: "POSITRON",
    description:
      "It is a user friendly mobile app that helps elected members of panchayats",
    image: assets.Positron,
    category: "ALL",
  },
  {
    name: "B.SMART ABACUS",
    subname: "B.SMART ABACUS",
    description:
      "Mobile app for well-established abacus training organization called B Smart Abacus",
    image: assets.Abacus,
    category: "EDUCATION",
  },
  {
    name: "BEHTAR BHARAT KI BUNIYAD",
    subname: "BEHTAR BHARAT KI BUNIYAD",
    description:
      " Digital fundraising platform designed for the Indian Youth Congress National Committee",
    image: assets?.Buniyad,
    category: "CROWD FUNDING",
  },
  {
    name: "THANAL C PROJECT",
    subname: "THANAL C PROJECT",
    description:
      "C-Project, initiated by Thanal, is a transformative endeavour aimed at providing a comprehensive range of services to Orphan families",
    image: assets?.Thanal,
    category: "ALL",
  },
  {
    name: "MYL DHOTI CHALLENGE",
    subname: "MYL DHOTI CHALLENGE",
    description:
      "The Dhoti Challenge, launched by the Muslim Youth League, the youth wing of the Indian Union Muslim League",
    image: assets?.Dhoti,
    category: "CROWD FUNDING",
  },
  {
    name: "IUML Membership",
    subname: "IUML Membership",
    description:
      "The app designed for the IUML National Committee to optimize and streamline the registration process for new members and efficiently manage executiv  committee activities.",
    image: assets?.Membership,
    category: "ALL",
  },
  {
    name: "IUML HADIYA",
    subname: "IUML HADIYA",
    description:
      "Application developed for fund collection program of the Indian Union Muslim League, is a political  party in Kerala recognized as a State Party by the Election Commission of India",
    image: assets?.Hadiya,
    category: "CROWD FUNDING",
  },
  {
    name: "Appolo Gold & Diamonds",
    subname: "Appolo Gold & Diamonds",
    description:
      "App provides user-friendly interface for convenient access to gold investment schemes and relevant information",
    image: assets?.Appolo,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "RENAI TV",
    subname: "RENAI TV",
    description:
      "The Renai TV application for KNM (Kerala Nadvathul  Mujahideen), is a socio-religious organization",
    image: assets?.Renai,
    category: "ALL",
  },
  {
    name: "WHITE GUARD",
    subname: "WHITE GUARD",
    description:
      "Application for registering volunteers who are part of the Muslim Youth League Kerala",
    image: assets?.White,
    category: "ALL",
  },
  {
    name: "K KARUNAKARAN FOUNDATION",
    subname: "K KARUNAKARAN FOUNDATION",
    description:
      "App dedicated to simplifying and ensuring  transparency in fundraising endeavors for the  K Karunakaran foundation in Thiruvananthapuram",
    image: assets?.Kkarunakaran,
    category: "CROWD FUNDING",
  },
  {
    name: "VERU",
    subname: "VERU",
    description:
      "The application for registration process of Veru campaign, it’s a innovative led by the Muslim Student Federation of Kerala, the student wing of All-India Muslim League",
    image: assets?.veru,
    category: "ALL",
  },
  {
    name: "KPT FOODS",
    subname: "KPT FOODS",
    description:
      "KPT foods is a robust software solution specifically designed for instant food processing unit",
    image: assets?.kpt,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "ADAX",
    subname: "ADAX",
    description:
      "Application for resort booking, streamlining the booking process and enhancing user experience with easy access to facilities and activities, while providing administrators with efficient management tools to optimize operations and customer satisfaction.",
    image: assets?.adax,
    category: "HOTEL MANAGEMENT",
  },
  {
    name: "PUMPKIN",
    subname: "PUMPKIN",
    description: "Pumpkin is a restaurant management application",
    image: assets?.pumpkin,
    category: "HOTEL MANAGEMENT",
  },
  {
    name: "Samak Restaurant",
    subname: "Samak Restaurant",
    description:
      "Samak is a dynamic restaurant app, enriching dining with entertainment. Its name embodies quality and versatility, promising a fulfilling experience.",
    image: assets?.samak,
    category: "HOTEL MANAGEMENT",
  },

  {
    name: "KN CYCLE",
    subname: "KN CYCLE",
    description:
      "The application kn cycle that handle production and assembly lines, as well as accounting tasks of the KN cycles, is a whole sale cycle shop  in Kerala",
    image: assets?.Kn,
    category: "ALL",
  },
  {
    name: "BLUETICK",
    subname: "BLUETICK",
    description: "Bluetick is an election management system",
    image: assets?.Bluetick,
    category: "ALL",
  },
  {
    name: "MYL Youth March",
    subname: "MYL Youth March",
    description:
      "MYL Youth March is an event involving participants  walking predetermined kilometers.",
    image: assets?.March,
    category: "ALL",
  },
  {
    name: "ELECTRONIC VOTING MACHINE",
    subname: "ELECTRONIC VOTING MACHINE",
    description:
      "C-Project, initiated by Thanal, is a transformative endeavour aimed at providing a comprehensive  range of services to Orphan families",
    image: assets?.Evm,
    category: "ALL",
  },
  {
    name: "DATES CHALLENGE",
    subname: "DATES CHALLENGE",
    description:
      "Application launched for fundraising activity of CH Centre Tirur, is a non-profit charitable organization  ",
    image: assets?.Challege,
    category: "CROWD FUNDING",
  },
  {
    name: "RAYYOU",
    subname: "RAYYOU",
    description:
      "The Application for Rayan Foundation, a non-profit organization committed to inclusive education an the development of individuals with disabilities ",
    image: assets?.rayyou,
    category: "ALL",
  },
  {
    name: "MYL KERALA ",
    subname: "MYL KERALA",
    description:
      "The application for Muslim Youth League, the youth wing of the Indian Union Muslim League provides a robust management system",
    image: assets?.Kerala,
    category: "ALL",
  },
  {
    name: "CHARMS",
    subname: "CHARMS",
    description:
      "Application for Charms ,it is a renowned food product brand that offers a wide range of high-quality spice powders, spice mixes, and masala blends in Kerala",
    image: assets?.charms,
    category: "ALL",
  },
  {
    name: "JANASAHAYI",
    subname: "JANASAHAYI",
    description:
      "Janasahayi, an innovative platform initiated by the Muslim Youth League, aims to provide various forms of assistance and online services offered by local bodies.",
    image: assets?.janasahayi,
    category: "ALL",
  },
  {
    name: "SAMOOHYA SURAKSHA",
    subname: "SAMOOHYA SURAKSHA",
    description:
      "The Samoohya Suraksha is a web application developed for Indian Union Muslim League units to efficiently manage and coordinate the Samoohya  Suraksha Padhathi initiative through a single platform",
    image: assets?.samoohyaa,
    category: "ALL",
  },
  {
    name: "NIRAM",
    subname: "NIRAM",
    description:
      "NIRAM is an exciting application that provides a platform for offstage competitions, including painting, quiz, drawing, and more",
    image: assets?.niram,
    category: "EDUCATION",
  },
  {
    name: "INSIDE IN ",
    subname: "INSIDE IN ",
    description:
      "InsideIn offers a feature for businesses and public places to register visitor details like name, contact, and entry date, aiding Covid-19 exposure tracking and contact tracing",
    image: assets?.inside,
    category: "ALL",
  },
  {
    name: "PRS REALITY",
    subname: "PRS REALITY",
    description:
      "PRS Reality is a cutting-edge application designed toconnect buyers and sellers in the real estate market",
    image: assets?.prs,
    category: "ALL",
  },
  {
    name: "EALA",
    subname: "EALA",
    description:
      "The application designed seamless resort management for EALA premier resort that showcases the spectacular beauty of nature in Kerala",
    image: assets?.eala,
    category: "HOTEL MANAGEMENT",
  },
  {
    name: "AIRON ",
    subname: "AIRON ",
    description:
      "The Airon application, an advanced internal management tool for Airon Technical Solutions India (P) Ltd. is a trusted provider of top-quality services ",
    image: assets?.airon,
    category: "ALL",
  },
  {
    name: "IUML PLATINUM JUBILEE",
    subname: "IUML PLATINUM JUBILEE",
    description:
      "Developed for the IUML Platinum Jubilee, The Indian Union Muslim League (IUML) is an Indian political party primarily based in the Indian state of Kerala",
    image: assets?.Platinum,
    category: "ALL",
  },
  {
    name: "LIMOUSINE SERVICE ",
    subname: "LIMOUSINE SERVICE ",
    description:
      "Limousine is a comprehensive application designed specifically for taxi services in Qatar ",
    image: assets?.limousine,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "MYL PAADASHALA",
    subname: "MYL PAADASHALA",
    description:
      "Application developed for the Muslim YouthLeague of Kerala, it simplifies the registration process for members attending campaigns and classes focused on community histories and priorities",
    image: assets?.padashala,
    category: "ALL",
  },
  {
    name: "NEPPOLI CLUB",
    subname: "NEPPOLI CLUB",
    description:
      "Software solution developed to collect funds from the public for a stadium project of the Neppoli club located in Poolammanna, Kerala",
    image: assets?.neppoli,
    category: "ALL",
  },
  {
    name: "MSF PAPER REVOLUTION",
    subname: "MSF PAPER REVOLUTION",
    description:
      "The Paper Revolution Application launched for the Muslim Student Federation (MSF) in Kerala, revolutionizes fund collection by selling old newspapers to support the federati",
    image: assets?.revelution,
    category: "CROWD FUNDING",
  },
  {
    name: "KNM",
    subname: "KNM",
    description:
      "The KNM Fund Collection is a dedicated software solution initiated for the fund collection activities of Kerala Nadvathul Mujahideen (KNM)",
    image: assets?.knm,
    category: "CROWD FUNDING",
  },
  {
    name: "DASH BY DELICIA",
    subname: "DASH BY DELICIA",
    description:
      "Dash by Delicia is an application for the multi-cuisine restaurant delicia located in Malappuram, Kerala. The app serves as e commerce platform for ordering delicious food items to shopping for daily essentials",
    image: assets?.delicia,
    category: "ALL",
  },
  {
    name: "GUPPY BANK APP",
    subname: "GUPPY BANK APP",
    description:
      "The Guppy Bank App is a user-friendly application designed to simplify transaction paperwork with banks, specifically for services like IMPS and RTGS",
    image: assets?.Guppy,
    category: "E-COMMERCE",
  },
  {
    name: "NAADU 2.0",
    subname: "NAADU 2.0",
    description:
      "Naadu 2.0 is an innovative app that serves as a communication platform between the public and political candidates",
    image: assets?.naadu,
    category: "ALL",
  },
  {
    name: "JAIHIND LOYALTY PROGRAM",
    subname: "JAIHIND LOYALTY PROGRAM",
    description:
      "JAI HIND TMT STEEL BARS, a wholesale distributor of TMT steel bars these application for their customers rewarding system",
    image: assets?.Jaihind,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "S KHAN",
    subname: "S KHAN",
    description:
      "App designed to assist jewellery shops and their clients in creating daily gold rate posters for effortless sharing on social media platforms.",
    image: assets?.skhan,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "SMAZIO",
    subname: "SMAZIO",
    description:
      "Dental clinic application for Smazio smile designers, is a dental clinic network in Kerala",
    image: assets?.smazio,
    category: "ALL",
  },

  //Add images after this section
  {
    name: "BAAN BAKERY",
    subname: "BAAN BAKERY",
    description:
      "The Baan Bakeries app simplifies ordering and delivery, improving efficiency for customers, drivers, and admins while supporting expansion across Saudi Arabia and other Arab countries.",
    image: assets?.baanBakery,
    category: "BUSINESS AUTOMATION",
  },
  {
    name: "RMS GOODS WAY",
    subname: "RMS GOODS WAY",
    description:
      "Application for logistics management, offering tools for vehicle tracking, trip management, and driver coordination, with real-time updates for fleet owners, drivers, and admins to ensure smooth operations.",
    image: assets?.goodsway,
    category: "BUSINESS AUTOMATION",
  },

  {
    name: "GRACE BOOKS",
    subname: "GRACE BOOKS",
    description:
      "Application for a book-selling platform offering easy access to free and paid books, secure transactions, and an admin tool for efficient management and sales insights, aiming to create a dynamic digital marketplace for readers.",
    image: assets?.grace,
    category: "E-COMMERCE",
  },
  // {
  //   name: "boCHE MART",
  //   subname: "boCHE MART",
  //   description:
  //     "E-commerce platform streamlining transactions with product details, offers, and secure payments, while providing admins with tools to manage products, orders, and customer interactions for a seamless experience.",
  //   image: assets?.bochemart,
  //   category: "E-COMMERCE",
  // },
  {
    name: "VIJAYAJALAKAM",
    subname: "VIJAYAJALAKAM",
    description:
      "LSS/USS Coaching app provides structured learning resources for students preparing for exams, with an admin tool for managing content, users, payments, and reports to enhance academic outcomes.",
    image: assets?.vijayajalakam,
    category: "EDUCATION",
  },
  {
    name: "MALABAR EDUFEST ",
    subname: "MALABAR EDUFEST ",
    description:
      "Malabar Edu Fest website connects users, employers, and admins to manage event participation, job registrations, and vacancies, while providing admins with tools for managing programs, users, and generating reports.",
    image: assets?.malabaredufest,
    category: "ALL",
  },
  {
    name: "MA",
    subname: "MA",
    description:
      "MA Literature Fest website allows users to easily register for the event, providing a seamless experience for participants and event organizers.",
    image: assets?.ma,
    category: "ALL",
  },
];

// MA Literature Fest website allows users to easily register for the event, providing a seamless experience for participants and event organizers.
