import React from "react";
import "./Service.css";
import { assets } from "../../Assets/Assets";
import AOS from "aos";
import "aos/dist/aos.css";

function Service() {
  AOS.init({
    duration: 1500,
  });
  return (
    <div className="service" id="service">
      <h3 data-aos="fade-down">Valuable Services</h3>
      <div className="items" data-aos="zoom-in-down">
        <div className="item">
          <img src={assets.Mobile} alt="App_Develop" loading="lazy" />
          <div className="containet-top">
            <h4>Mobile Application Development</h4>
            <p>
              Reach your audience on their preferred devices with our mobile app
              development services. From intuitive iOS and Android applications
              to cross-platform solutions that maximize your reach.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.Political} alt="Web Design" loading="lazy" />
          <div className="containet-top">
            <h4>Business Automation </h4>
            <p>
              We deliver cutting-edge business automation solutions to
              streamline operations, enhance productivity, and drive growth. Our
              experienced professionals provide tailored solutions to meet each
              client's unique needs with a commitment to excellence.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.Web_app} alt="Web Design" loading="lazy" />
          <div className="containet-top">
            <h4>Web Development </h4>
            <p>
              Harness the full potential of the web with our custom web
              development services. Whether you need a corporate website,
              e-commerce platform, or a robust web application.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.Ui_Design} alt="Web_Develop" loading="lazy" />
          <div className="containet-top">
            <h4>UX/UI Designing</h4>
            <p>
              Experience user-centric design with intuitive interfaces, seamless
              journeys, and innovative prototyping. Committed to accessibility
              and continuous improvement.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.Crowd} alt="UIdesign" loading="lazy" />
          <div className="containet-top">
            <h4>Crowdfunding Management Applications</h4>
            <p>
              Efficiently manage crowdfunding campaigns and engage supporters
              with our comprehensive management applications. From campaign
              planning to donor relationship management.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.ai_logo} alt="Graphic" loading="lazy" />
          <div className="containet-top">
            <h4>AI-Driven Technology Advancements</h4>
            <p>
              Experience the power of AI and machine learning with our
              cutting-edge solutions. From predictive analytics to natural
              language processing.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.Political} alt="Research" loading="lazy" />
          <div className="containet-top">
            <h4>Catalyzing Political Campaigns</h4>
            <p>
              Our specialized political fundraising applications are designed to
              maximize your fundraising efforts and streamline donor management.
              From intuitive online donation platforms to targeted fundraising
              campaign management tools.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.Payment} alt="Graphic" loading="lazy" />
          <div className="containet-top">
            <h4>Integrated Payment Solutions</h4>
            <p>
              Simplify financial transactions and enhance customer experience
              with our seamless payment integration services. Whether it's
              integrating multiple payment gateways or implementing secure
              checkout processes.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.User_resech} alt="Graphic" loading="lazy" />
          <div className="containet-top">
            <h4>User Research</h4>
            <p>
              User research is the bedrock of effective UX/UI design, guiding
              our understanding of user needs, behaviors, and motivations.
              Through methods like interviews, surveys.
            </p>
          </div>
        </div>
        <div className="item">
          <img src={assets.fintech} alt="Graphic" loading="lazy" />
          <div className="containet-top">
            <h4>Fintech Solution</h4>
            <p>
              Accelerate your financial services with our fintech applications
              designed to foster innovation and efficiency. From cutting-edge
              digital banking platforms to blockchain-powered payment systems.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
