import React, { useState, useEffect } from "react";
import { assets } from "../../Assets/Assets";
import "./Header.css";
import Mobilemenu from "../Mobilemenu/Mobilemenu";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const updateActiveSection = () => {
    const spotlightSection = document.getElementById("spotlight");
    const serviceSection = document.getElementById("service");
    const workSection = document.getElementById("work");
    const aboutSection = document.getElementById("footer");

    const scrollPosition = window.scrollY + window.innerHeight / 2;

    if (
      scrollPosition < serviceSection.offsetTop &&
      scrollPosition > spotlightSection.offsetTop
    ) {
      setActiveSection("spotlight");
    } else if (
      scrollPosition < workSection.offsetTop &&
      scrollPosition > serviceSection.offsetTop
    ) {
      setActiveSection("service");
    } else if (
      scrollPosition < aboutSection.offsetTop &&
      scrollPosition > workSection.offsetTop
    ) {
      setActiveSection("work");
    } else if (scrollPosition > aboutSection.offsetTop) {
      setActiveSection("footer");
    } else {
      setActiveSection("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updateActiveSection);

    return () => {
      window.removeEventListener("scroll", updateActiveSection);
    };
  }, []);

  return (
    <div>
      <header className={`${isMenuOpen ? "open" : ""}`}>
        <div className="header-logo">
          <img
            src={assets.Logo}
            alt="Logo"
            onClick={() => {
              scrollToSection("spotlight");
            }}
          />
        </div>
        <ul className="navbar">
          <li
            className={activeSection === "spotlight" ? "active" : ""}
            onClick={() => {
              setIsMenuOpen(false);
              scrollToSection("spotlight");
            }}
          >
            Home
          </li>
          <li
            className={activeSection === "service" ? "active" : ""}
            onClick={() => scrollToSection("service")}
          >
            Services
          </li>
          <li
            className={activeSection === "work" ? "active" : ""}
            onClick={() => scrollToSection("work")}
          >
            Projects
          </li>
          <li
            // className={activeSection === "footer" ? "active" : ""}
            onClick={() => navigate("/career")}
          >
            Career
          </li>
          <li
            className={activeSection === "footer" ? "active" : ""}
            onClick={() => scrollToSection("footer")}
          >
            Contact Us
          </li>
        </ul>
        <div className="menu" onMouseDown={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Mobilemenu
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          toggleMenu={toggleMenu}
        />
      </header>
    </div>
  );
}

export default Header;
