import React, { useEffect } from "react";
import "./Privacy.css";
import { Link } from "react-router-dom";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-main">
      <div className="privacy-content">
        <h5>Privacy Policy</h5>
        <p>
          Welcome to Spinecodes . We are committed to protecting your privacy
          and ensuring that your personal information is handled in a safe and
          responsible manner. This Privacy Policy outlines how we collect, use,
          and protect your information when you visit our website
          <a href="mailto:info@spinecodes.com">Info@spinecodes.com</a> , and use
          our services.
        </p>
        <h6>Information We Collect</h6>
        <p>
          Personal Identification Information: Name, email address, phone
          number, and other contact details. Technical Information: IP address,
          browser type, operating system, referring URLs, and other technical
          information collected when you visit our Website. Usage Information:
          Information about how you use our Website and services, including the
          pages you visit and the actions you take. Communication Information:
          Information you provide when you contact us, including the content of
          your communications and the contact details you provide.
        </p>
        <h6>How We Use Your Information</h6>
        <p>
          To Provide Services: To deliver our services and products, process
          transactions, and manage customer relationships. To Improve Our
          Services: To understand how our services are used and make
          improvements to our Website and offerings. To Communicate: To respond
          to your inquiries, provide customer support, and send you updates and
          promotional materials. To Comply with Legal Obligations: To comply
          with applicable laws, regulations, and legal processes.
        </p>
        <h6>Sharing Your Information</h6>
        <p>
          Service Providers: We may share your information with third-party
          service providers who assist us in operating our Website and providing
          our services. Legal Compliance: We may disclose your information when
          required by law or to protect our rights, property, or safety.
          Business Transfers: In the event of a merger, acquisition, or sale of
          our business, your information may be transferred to the new owners.
        </p>
        <h6> Security of Your Information</h6>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no internet-based
          service can be 100% secure, and we cannot guarantee the absolute
          security of your information.
        </p>
        <h6>Your Rights</h6>
        <p>
          Access: You have the right to access the personal information we hold
          about you. Correction: You have the right to request the correction of
          inaccurate or incomplete information. Deletion: You have the right to
          request the deletion of your personal information, subject to certain
          legal obligations. Objection: You have the right to object to the
          processing of your personal information in certain circumstances.
          Restriction: You have the right to request the restriction of
          processing your personal information.
        </p>
        <h6>Changes to This Privacy Policy</h6>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will post any updates on
          this page, and we encourage you to review this Privacy Policy
          periodically.
        </p>
        <h6>Contact Us</h6>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p>
          Spinecodes 2nd Floor, V.K Trade Centre, Tirur Road, Down Hill,
          Malappuram - 676 519, Kerala - India
        </p>
        <a href="tel:+917736082220" className="lnk">
          Phone : +91 8547274065
        </a>
        <a href="mailto:info@spinecodes.com" className="lnk">
          Mail : Info@spinecodes.com
        </a>
        <Link to="/">
          <button className="btn">Home</button>
        </Link>
      </div>
    </div>
  );
};

export default Privacy;
