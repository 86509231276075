import React, { useEffect } from "react";
import "./Terms.css";
import { Link } from "react-router-dom";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-content">
      <div className="contents-ter">
        <h5>Terms and Conditions</h5>
        <p>
          Welcome to Spinecodes . By accessing and using our website,
          <a href="mailto:info@spinecodes.com">Info@spinecodes.com</a>, and our
          services, you agree to comply with and be bound by the following terms
          and conditions . Please read these Terms carefully. If you do not
          agree with any part of these Terms, you must not use our Website or
          services.
        </p>
        <h6>Use of Our Website and Services</h6>
        <p>
          Eligibility: You must be at least 18 years old to use our Website and
          services. By using our Website, you represent and warrant that you
          meet this age requirement. 1.2 Account Registration: To access certain
          features of our services, you may need to create an account. You agree
          to provide accurate and complete information when registering an
          account and to keep this information up to date. 1.3 Prohibited
          Activities: You agree not to use our Website or services for any
          unlawful or prohibited activities, including but not limited to:
          Violating any applicable laws or regulations. Infringing on the rights
          of others. Distributing viruses or other harmful software. Engaging in
          fraudulent or deceptive practices.
        </p>
        <h6>Intellectual Property</h6>
        <p>
          Ownership: All content on our Website, including text, graphics,
          logos, and software, is the property of Spinecodes or its content
          suppliers and is protected by intellectual property laws. License: We
          grant you a limited, non-exclusive, non-transferable license to access
          and use our Website and services for personal and commercial purposes.
          This license does not permit you to reproduce, distribute, modify, or
          create derivative works from our content without our express written
          permission.
        </p>
        <h6>Privacy Policy</h6>
        <p>
          Your use of our Website and services is also governed by our Privacy
          Policy, which can be found here. Please review our Privacy Policy to
          understand our practices regarding the collection and use of your
          personal information.
        </p>
        <h6>Cancellation & Refund Policy</h6>
        <p>
          SPINECODES SOLUTIONS LLP believes in helping its customers as far as
          possible, and has therefore a liberal cancellation policy. Under this
          policy: • Cancellations will be considered only if the request is made
          immediately after placing the order. However, the cancellation request
          may not be entertained if the orders have been communicated to the
          vendors/merchants and they have initiated the process of shipping
          them. • SPINECODES SOLUTIONS LLP does not accept cancellation requests
          for perishable items like flowers, eatables etc. However,
          refund/replacement can be made if the customer establishes that the
          quality of product delivered is not good. • In case of receipt of
          damaged or defective items please report the same to our Customer
          Service team. The request will, however, be entertained once the
          merchant has checked and determined the same at his own end. This
          should be reported within 2 Days days of receipt of the products. In
          case you feel that the product received is not as shown on the site or
          as per your expectations, you must bring it to the notice of our
          customer service within 2 Days days of receiving the product. The
          Customer Service Team after looking into your complaint will take an
          appropriate decision. • In case of complaints regarding products that
          come with a warranty from manufacturers, please refer the issue to
          them. In case of any Refunds approved by the SPINECODES SOLUTIONS LLP,
          it’ll take 3- 5 Days days for the refund to be processed to the end
          customer.
        </p>
        <h6> Disclaimers and Limitation of Liability</h6>
        <p>
          Disclaimers: Our Website and services are provided on an "as is" and
          "as available" basis. We make no representations or warranties of any
          kind, express or implied, regarding the operation or availability of
          our Website or services. 4.2 Limitation of Liability: To the fullest
          extent permitted by law, Spinecodes shall not be liable for any
          indirect, incidental, special, or consequential damages arising out of
          or in connection with your use of our Website or services.
        </p>
        <h6>Termination</h6>
        <p>
          We reserve the right to terminate or suspend your access to our
          Website and services at any time, without notice, for any reason,
          including but not limited to your violation of these Terms.
        </p>
        <h6>Governing Law</h6>
        <p>
          These Terms and your use of our Website and services shall be governed
          by and construed in accordance with the laws of India, without regard
          to its conflict of laws principles. You agree to submit to the
          exclusive jurisdiction of the courts located in Kerala, India, for the
          resolution of any disputes.
        </p>
        <h6>Changes to These Terms</h6>
        <p>
          We may update these Terms from time to time to reflect changes in our
          practices or legal requirements. We will post any updates on this
          page, and we encourage you to review these Terms periodically.
        </p>
        <h6>Contact Us</h6>
        <p>
          Spinecodes 2nd Floor, V.K Trade Centre, Tirur Road, Down Hill,
          Malappuram - 676 519, Kerala - India
        </p>
        <a href="tel:+917736082220" className="lnk">
          Phone : +91 8547274065
        </a>
        <a href="mailto:info@spinecodes.com" className="lnk">
          Mail : info@spinecodes.com
        </a>
        <Link to="/">
          <button className="btn">Home</button>
        </Link>
      </div>
    </div>
  );
};

export default Terms;
